import axios from 'axios'
import { API_VERSION, BASE_URL } from '../Config/Api'
import {getTokenLocalStorage} from '../Helpers/auth'
const path = '/reservation'

export const verifyGuest = async(token, email) => {
    return await axios.get(BASE_URL + path + API_VERSION + `/verify-guest?token=${token}&email=${email}`)
}
export const CreateReservationAuth = async(state) => {
    return await axios.post(BASE_URL + path + API_VERSION + '/create',state,{
        headers: {
            'Authorization': 'Bearer ' + getTokenLocalStorage()
        }
    })
}

export const CreateReservationGuest = async(state) => {
    return await axios.post(BASE_URL + path + API_VERSION + '/guest',state)
}

export const GuestContinueReservation = async(state, token, email) => {
    
    return await axios.post(BASE_URL + path + API_VERSION + `/guest-continue?token=${token}`,state)
}

export const getReportReservation = async(payload) => {
    
    return await axios.get(BASE_URL + path + API_VERSION + `/report/${payload}`)
}

export const getMyReservation = async() => {
    
    return await axios.get(BASE_URL + path + API_VERSION + `/me`, {
        headers: {
            'Authorization': 'Bearer ' + getTokenLocalStorage()
        }
    })
}