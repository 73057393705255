import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getMyReservation, getReportReservation } from '../../../../Services/reservation';
import {formatReservationDateRange, formatDate} from '../../../../Helpers/date'

const ListReservation = () => {
    const [detailUuid, setDetailUuid] = useState('')
    const [showModal, setShowModal]= useState(false)
    const { data: detailReservation, isFetched: detailFetch, isSuccess: detailSuccess, isLoading: detailIsloading, refetch: detailRefatch } = useQuery(
      ["getDetail", detailUuid],
      () => getReportReservation(detailUuid),
      {
        refetchOnWindowFocus: false,
        retry: 2,
        enabled: !!detailUuid,
      }
    );
    const { data: myReservation, isError, isFetched, isSuccess, isLoading } = useQuery(
        "getProfileDetail",
        () => getMyReservation(),
        {
          refetchOnWindowFocus: false,
          retry: 2,
        }
      )
      console.log(detailReservation, 'data')

      const handleShowModal = (uuid) => {
        setDetailUuid(uuid); // Mengatur UUID ke dalam state detailUuid
        setShowModal(true);
      };

      useEffect(() => {
        if(detailReservation?.name){
          setShowModal(true)
        }
      },[detailReservation?.name])
      
      const modalClose = () => {
        setDetailUuid('')
        setShowModal(false)
      }

  return (
    <div className="header py-28 text-center md:pt-36 lg:text-left xl:pt-44 xl:pb-32 overflow-x-auto">
        <div className="container">
            <h1>Riwayat Reservasi</h1>
      <table className="min-w-full">
        <thead>
          <tr>
            <th className="bg-teal-600 text-white border border-teal-600  p-2">No. Reservasi</th>
            <th className="bg-teal-600 text-white border border-teal-600  p-2">Nama Dokter</th>
            <th className="bg-teal-600 text-white border border-teal-600  p-2">Status</th>
            <th className="bg-teal-600 text-white border border-teal-600  p-2">Tipe Konsultasi</th>
            <th className="bg-teal-600 text-white border border-teal-600  p-2">Detail</th>
          </tr>
        </thead>
        <tbody>
          { isSuccess && myReservation?.data?.data?.map((item, index) => (
            <tr key={index}>
              <td className="text-black border border-teal-600 p-2">{item?.no_reservation}</td>
              <td className="text-black border border-teal-600 p-2">{item?.doctor_name}</td>
              <td className="text-black border border-teal-600 p-2">{item?.status}</td>
              <td className="text-black border border-teal-600 p-2">
                {item?.layanan.toUpperCase()}
              </td>
              <td className="text-black border border-teal-600 p-2">
                  <button
                    onClick={() => handleShowModal(item.no_reservation)}
                    className="bg-orange-500 text-white font-bold py-2 px-4 rounded-full mt-4"
                  >
                    Detail
                  </button>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      {
      showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center ">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="container grid grid-cols-1 bg-white w-full mx-auto rounded shadow-lg z-50 overflow-y-auto">
            {/* Konten Modal */}
            <div className="modal-content py-4 text-left px-6 ">
              {/* Tombol Tutup Modal */}
              <div className="flex justify-end items-center">
                <button
                  onClick={() => modalClose()}
                  className="text-gray-400 hover:text-gray-800 focus:outline-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <h3 className="text-2xl font-semibold mb-2">Detail</h3>
              <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-10">
                <div>
                  <p className='py-2'><strong>No. Reservation:</strong> {detailReservation?.data?.data?.no_reservation}</p>
                  <p className='py-2'><strong>Profile Name:</strong> {detailReservation?.data?.data?.name}</p>
                  <p className='py-2'><strong>Description:</strong> {detailReservation?.data?.data?.description}</p>
                  <p className='py-2'><strong>Email:</strong> {detailReservation?.data?.data?.email}</p>
                </div>
                <div>
                  <p className='py-2'><strong>Jadwal Janji:</strong> {formatReservationDateRange(detailReservation?.data?.data?.time_reservation_start, detailReservation?.data?.data?.time_reservation_end)}</p>
                  <p className='py-2'><strong>Terbuat pada:</strong> {formatDate(detailReservation?.data?.data?.created_at)}</p>
                  <p className='py-2'><strong>Dokter:</strong> {detailReservation?.data?.data?.doctor_name}</p>
                  <p className='py-2'><strong>Status:</strong> {detailReservation?.data?.data?.status}</p>
                  {/* Add more fields as needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      
     }
      </div>
    </div>
  );
};

export default ListReservation;
