import axios from "axios"
import {BASE_URL, API_VERSION} from '../Config/Api'
const path = '/master/services'
export const getCategory = async () => {
    return await axios.get(BASE_URL + path + API_VERSION )
}

export const getAllServices = async () => {
    const data = await axios.get(BASE_URL + path + API_VERSION )
    return data
}