import Swal from "sweetalert2"
import { LoginService } from "../../Services/auth"

const AuthActionType = {
    REGISTER_SUCCESS: "REGISTER_SUCCESS",
    REGISTER_FAIL: "REGISTER_FAIL",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS"
}

const RegisterAuthAction = () => { }

const LoginAuthAction = (state, path) => {
    return async (dispatch) => {
        try {
            const login = await LoginService(state)
            const { data } = login
            dispatch({ type: AuthActionType.LOGIN_SUCCESS, payload: data })
            let timerInterval;
            Swal.fire({
                title: "Sedang memuat data..",
                html: "Akan selesai dalam <b></b> milliseconds.",
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    Swal.showLoading();
                    const timer = Swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                        timer.textContent = `${Swal.getTimerLeft()}`;
                    }, 100);
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then((result) => {
                if (result.dismiss === Swal.DismissReason.timer) {
                    window.location.href = path
                }
            });
        } catch (err) {

            alert(err.response.data?.message ?? err.response?.data?.error?.message)
            dispatch({ type: AuthActionType.LOGIN_FAILED, payload: {} })

        }
    }
}

const LogoutAuthAction = (state, path) => {
    return async (dispatch) => {
        try {
            dispatch({ type: AuthActionType.LOGOUT_SUCCESS, payload: {} })
            window.location.href = path
        } catch (err) {
            console.log(err)
            dispatch({ type: AuthActionType.LOGOUT_SUCCESS, payload: {} })
        }
    }
}


export { AuthActionType, LoginAuthAction, LogoutAuthAction }