import { useEffect, useState } from 'react';
import {BsCheck, BsX} from 'react-icons/bs'
import { useQuery } from 'react-query';
import { useLocation } from 'react-router';
import { revokeTokenEmailService } from '../../Services/token';


const ConfirmEmail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); // mendapatkan value dari parameter 'param1' dalam query string
    const id = queryParams.get('id'); 
    const [message, setMessage] = useState('')
    
    const {data, isError, isFetching, isSuccess, refetch} = useQuery('revokeToken', () => revokeTokenEmailService(token,id), {
        refetchOnWindowFocus: false,
        retry:2,
        onSuccess: (data => {
            if(data){
                setTimeout(() => {
                    window.location.href = '/login'
                }, 2000);
                
            }
        }),
        onError: (data => {
            setMessage(data.response.data.error.message)
            setTimeout(() => {
                window.location.href = '/'
            }, 2000);
        })
    })
    if(isError){
        return (
            <>
             <center>
           
                <>
                <BsX size={120} color="red" />
                <h5>{message}</h5>
                </>
            
        </center>
            </>
        )
    }
    return (
      <div >
        <center>
            {isSuccess && (
                <>
                <BsCheck size={120} color="green" />
                <h5>Congratulations Email Confirmed</h5>
                </>
            )}
            
        </center>
      </div>
    );
  }
  
  export default ConfirmEmail;