import React, { useCallback, useEffect, useState } from "react"
import { useQuery } from 'react-query'
import { getDoctors } from '../../Services/profile'
import { getAllServices } from "../../Services/category"
import { getUserLocalStorage } from "../../Helpers/auth"
const HomePage = (props) => {

  const { data: doctors, isSuccess: doctorsSuccess, isLoading: doctorLoading } = useQuery('getDoctor', () => getDoctors(), {
    refetchOnWindowFocus: false,
    retry: 2
  })

  const { data: layananList, isSuccess: layananSuccess, isLoading: layananLoad } = useQuery('getLayanan', () => getAllServices(), {
    refetchOnWindowFocus: false,
    retry: 2
  })

  const [auth, setAuth] = useState(false);


  useEffect(() => {
    isLogged();
    return () => { };
  }, []);

  const isLogged = () => {
    const storage = getUserLocalStorage();
    if (storage) setAuth(true)
  };


  return (
    <>



      <header id="header" className="header py-28 text-center md:pt-36 lg:text-left xl:pt-44 xl:pb-32">
        <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
          <div className="mb-16 lg:mt-32 xl:mt-40 xl:mr-12">
            <h1 className="text-4xl font-bold mb-5">Artikular Klinik</h1>
            <p className="text-lg text-gray-600 mb-8">Kami siap melayani anda 24/7, temukan kebutuhan anda disini</p>

            <a className="btn-solid-lg py-3 px-8 text-white rounded-full hover:bg-secondary-blue" href={auth ? '/jadwal-dokter' : '/login'}>
              Temukan Jadwal Dokter
            </a>
          </div>
          <div className="xl:text-right">
            <img className="inline" src="https://res.cloudinary.com/dk0z4ums3/image/upload/v1691381774/setting/1691381773.png" alt="alternative" />
          </div>
        </div>
      </header>

      <div className="pt-4 text-center">
        <div className="container px-4 sm:px-8 xl:px-4">
          <p className="mb-4 text-gray-800 text-3xl leading-10 lg:max-w-5xl lg:mx-auto"> Jenis Layanan</p>
        </div>
      </div>

      <div id="features" className="cards-1">
        <div className="container px-4 sm:px-8 xl:px-4">

          {
            layananSuccess && layananList?.data?.data?.map((data) => (
              <div className="card">
                <div className="card-image">
                  <img src={data?.image} alt="alternative" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">{data?.name}</h5>
                  <p className="mb-4">

                  </p>
                </div>
              </div>
            )
            )
          }


        </div>
      </div>



      <div id="details" className="pt-12 pb-16 lg:pt-16">
        <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-12 lg:gap-x-12">
          <div className="lg:col-span-5">
            <div className="mb-16 lg:mb-0 xl:mt-16">
              <h2 className="mb-6">Artikular dan Pelayanan Orthopedi</h2>
              <p className="mb-4">Kami adalah klinik kesehatan terkemuka yang didedikasikan untuk memberikan layanan perawatan orthopedi yang berkualitas.</p>
              <p className="mb-4">Berbasis pada pengalaman luas tim kami dalam merawat masalah ortopedi, kami berkomitmen untuk memberikan hasil terbaik kepada pasien kami.</p>
              <p className="mb-4">Kami selalu berusaha membantu pasien dengan berbagai masalah ortopedi, mulai dari cedera, gangguan tulang, hingga prosedur pembedahan yang canggih.</p>
            </div>
          </div>
          <div className="lg:col-span-7">
            <div className="xl:ml-14">
              <img className="inline" src="https://www.pngmart.com/files/21/Male-Doctor-PNG-File.png" alt="alternative" />
            </div>
          </div>
        </div>
      </div>





      <div className="py-24">
        <div className="container px-4 sm:px-8 lg:grid lg:grid-cols-12 lg:gap-x-12">
          <div className="lg:col-span-7">
            <div className="mb-12 lg:mb-0 xl:mr-14">
              <img className="inline" src="https://img-cdn.medkomtek.com/JUb0R1RB_zIjRngpnhehymQ7SRA=/468x0/smart/filters:quality(75):strip_icc():format(webp)/homepage/jt95YhjqyQkidTfcgwXEF/original/1665634255-Dr-Radi.png" alt="alternative" />
            </div>
          </div>
          <div className="lg:col-span-5">
            <div className="xl:mt-12">
              <h2 className="mb-6">Visi dan Misi Kami</h2>
              <ul className="list mb-7 space-y-2">
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div><strong>Visi:</strong> Menjadi penyedia layanan kesehatan terkemuka yang memberikan perawatan ortopedi terbaik.</div>
                </li>
                <li className="flex">
                  <i className="fas fa-chevron-right"></i>
                  <div><strong>Misi:</strong> Mengutamakan kebutuhan pasien dengan menyediakan perawatan ortopedi berkualitas tinggi, penelitian dan inovasi, serta pelayanan yang peduli.</div>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>

      <div className="py-16 bg-gray-100">
        <div className="container px-4 sm:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-semibold mb-4">Apa Kata Mereka?</h2>
          </div>
          <div className="flex flex-wrap justify-center ">

            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
              <div className="bg-white rounded-lg shadow-md p-6 text-center">
                <div className="w-20 h-20 mx-auto mb-4 rounded-full overflow-hidden">
                  <img src="https://placekitten.com/200/200" alt="Profil" className="object-cover w-full h-full" />
                </div>
                <p className="text-gray-600">John Doe</p>
                <p className="text-gray-800">"Saya sangat puas dengan pelayanan yang diberikan oleh tim klinik ini. Mereka sangat profesional dan perawatan ortopedi yang mereka berikan sangat berkualitas."</p>
              </div>
            </div>


            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
              <div className="bg-white rounded-lg shadow-md p-6 text-center">
                <div className="w-20 h-20 mx-auto mb-4 rounded-full overflow-hidden">
                  <img src="https://placekitten.com/200/201" alt="Profil" className="object-cover w-full h-full" />
                </div>
                <p className="text-gray-600">Jane Smith</p>
                <p className="text-gray-800">"Pelayanan klinik ini sungguh luar biasa. Mereka memberikan perawatan yang sangat peduli dan profesional. Saya merasa sangat nyaman."</p>
              </div>
            </div>


            <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4">
              <div className="bg-white rounded-lg shadow-md p-6 text-center">
                <div className="w-20 h-20 mx-auto mb-4 rounded-full overflow-hidden">
                  <img src="https://placekitten.com/200/202" alt="Profil" className="object-cover w-full h-full" />
                </div>
                <p className="text-gray-600">Alice Johnson</p>
                <p className="text-gray-800">"Klinik ini telah membantu saya pulih dari cedera ortopedi saya. Saya sangat berterima kasih atas perawatan mereka."</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="counter">
        <div className="container px-4 sm:px-8">


          <div id="counter">
            <div className="cell">
              <div className="counter-value number-count" data-count="231">1000+</div>
              <p className="counter-info">Pasien</p>
            </div>
            <div className="cell">
              <div className="counter-value number-count" data-count="385">30+</div>
              <p className="counter-info">Dokter Spesialis</p>
            </div>
            <div className="cell">
              <div className="counter-value number-count" data-count="159">10+</div>
              <p className="counter-info">Layanan</p>
            </div>

          </div>


        </div>
      </div>
      <div className=" bg-white-100">
        <div className="container px-4 sm:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-semibold mb-4">Daftar Dokter</h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">

            {
              doctorsSuccess && doctors?.data?.data?.map((doctor) => (
                <a href={`/dokter/detail/${doctor.slug}`}>
                  <div className=" rounded-lg flex items-center">
                    <div className="w-full h-48 mx-4">
                      <img src={doctor?.image} alt="Dokter" className="object-cover w-full h-full" />
                    </div>
                    <div>
                      <p className="text-gray-800 font-semibold">{doctor.name}</p>
                      {
                        doctor?.layanan?.map((service) => (
                          <p style={{ fontSize: '10px' }} className="text-gray-600">
                            {service.name},
                          </p>
                        ))
                      }
                    </div>
                  </div>
                </a>
              ))
            }


          </div>
        </div>
      </div>



    </>
  );
}


export default HomePage