import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GuestProfileService } from '../../../../Services/auth';
import { useQuery } from 'react-query';
import { updateProfile } from '../../../../Services/profile';
import { showError, showSuccess } from '../../../../Helpers/message';

const UserProfile = ({ isLogedIn }) => {
  const [formData, setFormData] = useState({
    name: '',
    birth: '',
    phone_number: '',
    gender: '',
    address: '',
  });

  const [dateBirth, setDatebirth] = useState(new Date())
  const { data: profile, isError, isFetched, isSuccess: profileSuccess, isLoading, refetch } = useQuery(
    "getScheduleListDoctor",
    () => GuestProfileService(),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isLogedIn,
      onSuccess: (res) => {
        setDatebirth(res?.data?.data?.birth?.split(' ')[0])
      }
    }
  )

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [result, setResult] = useState({
    successMessage: '',
    errorMessge: '',
    success: false,
    error: false
  })

  useEffect(() => {
    if (profileSuccess) {
      setFormData({
        name: profile?.data?.data?.name,
        birth: profile?.data?.data?.birth !== null ? profile?.data?.data?.birth?.split(' ')[0] : '',
        phone_number: profile?.data?.data?.phone_number,
        gender: profile?.data?.data?.gender,
        address: profile?.data?.data?.address,
      });
    }
  }, [profileSuccess]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsSaving(true);

    try {
      const response = await updateProfile(formData)
      
      if (response?.data?.statusCode === 200) {
        setIsEditing(false);
        setResult({
          successMessage: 'Data berhasil diubah',
          success: true
        })
        refetch()
        // Proses berhasil disimpan di server
        // Lakukan tindakan sesuai di sini jika diperlukan
      } else {
        // Gagal menyimpan data
        // Lakukan tindakan sesuai di sini jika diperlukan
        setResult({
          errorMessge: 'Data berhasil diubah',
          error: true
        })
      }
    } catch (error) {
      console.error('Kesalahan saat mengirim permintaan ke server:', error);
      // Tangani kesalahan jika terjadi kesalahan saat mengirim permintaan
      // Anda dapat menampilkan pesan kesalahan atau mengambil tindakan lain yang sesuai
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  return (
    <div className="header py-28 justify-cnter md:pt-36 lg:text-left xl:pt-44 xl:pb-32 overflow-x-auto">
      <div className="container rounded-lg p-4 shadow-md">
        <img src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png" className="w-32 h-32 rounded-full shadow-md mx-auto" />
        <h2 className="text-2xl font-semibold text-center mt-4">{profile?.data?.data.name}</h2>
        <p className="text-gray-600 text-center mt-2">{profile?.data?.data?.email}</p>

        {result.success && showSuccess(result.successMessage)}
        {result.error && showError(result.errorMessge)}
        {isEditing && profileSuccess ? (
          <div className="mt-4">
            <h3 className="text-center text-xl font-semibold">Edit Profil</h3>
            <form className="w-2/3 mx-auto mt-2">
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold" htmlFor="name">
                  Nama
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  defaultValue={formData.name}
                  onChange={handleChange}
                  className="appearance-none bg-transparent py-3 border-b border-teal-600 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold" htmlFor="birth">
                  Tanggal Lahir
                </label>
                <input
                  type="date"
                  id="birth"
                  name="birth"
                  defaultValue={formData.birth}
                  onChange={handleChange}
                  className="appearance-none bg-transparent py-3 border-b border-teal-600 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold" htmlFor="phone_number">
                  Nomor handphone
                </label>
                <input
                  type="text"
                  id="phone_number"
                  name="phone_number"
                  defaultValue={formData.phone_number}
                  onChange={handleChange}
                  className="appearance-none bg-transparent py-3 border-b border-teal-600 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Jenis Kelamin</label>
                <div>
                  <input
                    type="radio"
                    id="male"
                    name="gender"
                    defaultValue="Laki - Laki"
                    checked={formData.gender === 'Laki - Laki'}
                    onChange={handleChange}
                  />
                  <label htmlFor="male" className="ml-2">
                    Laki-laki
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="female"
                    name="gender"
                    defaultValue="Perempuan"
                    checked={formData.gender === 'Perempuan'}
                    onChange={handleChange}
                  />
                  <label htmlFor="female" className="ml-2">
                    Perempuan
                  </label>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold" htmlFor="address">
                  Alamat
                </label>
                <textarea
                  id="address"
                  name="address"
                  defaultValue={formData.address}
                  onChange={handleChange}
                  className="appearance-none bg-transparent py-3 border-b border-teal-600 w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                />
              </div>
            </form>
            <button
              className="bg-teal-600 justify-end text-white py-2 px-4 rounded-md"
              onClick={handleSubmit}
              disabled={isSaving}
            >
              {isSaving ? 'Menyimpan...' : 'Simpan'}
            </button>
          </div>
        ) : (
          <center>
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-md mt-4"
              onClick={handleEditClick}
            >
              Edit Profil
            </button>
          </center>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
