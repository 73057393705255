import axios from "axios"
import { API_VERSION, BASE_URL } from "../Config/Api"
import { getTokenLocalStorage } from "../Helpers/auth"
const path = '/token'
export const getCsrfToken = async() => {
    return await axios.get(BASE_URL + path + API_VERSION + '/csrf')
}

export const revokeTokenEmailService = async (token,id) => {
    return await axios.get(BASE_URL + path + API_VERSION + `/email-confirmation?token=${token}&id=${id}`)
}

export const requestEmailConfirmation = async () => {
    return await axios.post(BASE_URL + path + API_VERSION + `/request-email-confirmation`,{},{
        headers: {
            'Authorization': 'Bearer ' + getTokenLocalStorage()
        }
    })
}