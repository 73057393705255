

import axios from "axios"
import {BASE_URL, API_VERSION} from '../Config/Api'
const path = '/doctor/schedule'
export const getCategory = async () => {
    return await axios.get(BASE_URL + path + API_VERSION )
}

export const getAllScheduleDoctor = async (specialist_uuid) => {
    const data = await axios.get(BASE_URL + path + API_VERSION + `${specialist_uuid ? '?specialist_uuid=' + specialist_uuid : ''}` )
    return data
}

export const getScheduleByServiceAndDoctorUuid = async (doctor_uuid, service_uuid) => {
    const data = await axios.get(BASE_URL + path + API_VERSION + `/specific?doctor_uuid=${doctor_uuid}&service_uuid=${service_uuid}` )
    return data
}