import React from 'react';
import { useParams } from 'react-router';
import {profileDetailDoctor} from '../../Services/profile'
import { useQuery } from 'react-query';
import {formatDate} from '../../Helpers/date'

const DoctorDetail = () => {
  let {slug} = useParams()

  const { data: detailDoctor, isError, isFetched, isSuccess, isLoading } = useQuery(
    "getProfileDetail",
    () => profileDetailDoctor(slug),
    {
      refetchOnWindowFocus: false,
      retry: 2,

    }
  )

  console.log(detailDoctor, 'slug')

  return (
    <div className="header py-28 justify-cnter md:pt-36 lg:text-left xl:pt-44 xl:pb-32 overflow-x-auto">
      <div className='container'>
        <div className="bg-white-800 py-3 shadow-lg rounded-md flex flex-col md:flex-row md:items-center">
          <div className="md:w-1/4">
            <img
              src="https://static.vecteezy.com/system/resources/previews/019/896/008/original/male-user-avatar-icon-in-flat-design-style-person-signs-illustration-png.png"
              alt="Dokter 2"
              className="w-48 h-48 rounded-full mx-auto"
            />
          </div>
          <div className="md:w-3/4">
          <h1 className="text-3xl font-bold">{detailDoctor?.name}</h1>
          <p>
            Spesialis:{" "}
            {detailDoctor?.layanan?.map((exp, index) => (
              index === 0
                ? exp.name
                : index === detailDoctor?.layanan.length - 1
                ? ` & ${exp.name}`
                : `, ${exp.name}`
            ))}
          </p>
         
        </div>



        </div>
      </div>

      <hr className="my-6" />

      <div className="container md:flex-row md:items-center">
        <h2 className="text-2xl font-semibold">Informasi Tambahan</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <h3 className="text-xl font-semibold">Description</h3>
            <div className="bg-white rounded-lg shadow-md p-4">
              <p className="text-gray-600">{detailDoctor?.description}</p>
            </div>
          </div>
          <div>
            <div className="space-y-4">
              <div>
                <h3 className="text-xl font-semibold">Pengalaman Kerja</h3>
                {detailDoctor?.experience.map((exp, index) => (
                  <div key={index} className="bg-white rounded-lg shadow-md p-4 mt-4">
                    <p className="text-gray-600">
                      Pengalaman: {exp.name}
                      <br />
                      Tanggal Mulai: {exp.start_date?.split(' ')[0]}
                      <br />
                      Tanggal Selesai: {exp.is_present ? "Sampai Sekarang" : exp.end_date?.split(' ')[0]}
                    </p>
                  </div>
                ))}
              </div>
              <div>
                <h3 className="text-xl font-semibold">Pengalaman Belajar</h3>
                {detailDoctor?.studies.map((exp, index) => (
                  <div key={index} className="bg-white rounded-lg shadow-md p-4 mt-4">
                    <p className="text-gray-600">
                      Pengalaman: {exp.name}
                      <br />
                      Tanggal Mulai: {exp.start_date?.split(' ')[0]}
                      <br />
                      Tanggal Selesai: {exp.is_present ? "Sampai Sekarang" : exp.end_date?.split(' ')[0]}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorDetail;
