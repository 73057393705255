import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FormGroup, Label, Input, Button, Form } from 'reactstrap'
import { COLOR } from '../../Atom/Color'
import { connect } from 'react-redux'
import { LoginAuthAction } from "../../../redux/actions/authAction";
import { decoreateUser } from "../../../Helpers/auth";
import { MAIN_COLOR } from "../../../Helpers/Colour";

function LoginPage(props) {
  const { login } = props
  const [loginState, setLoginState] = useState({})

  return (
    <div className="header min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full">
        <div className="text-center">
          <img src="https://res.cloudinary.com/werich1/image/upload/v1698080808/perscience/assets/LOGO_ARTIKULAR_BARU_tranparent-01_sfhykh.png" alt="login_image" />
        </div>
        <div className="bg-white p-8 rounded shadow-lg">
          <h1 className="text-2xl font-bold text-center mb-4">Login</h1>
          <form onSubmit={(event) => {
            event.preventDefault();
            login(loginState, '/');
          }}>
            <div className="mb-4">
              <label htmlFor="username" className="block text-gray-700">Email</label>
              <input
                onChange={(event) => {
                  const username = event.target.value;
                  setLoginState({ ...loginState, username });
                }}
                id="username"
                name="username"
                placeholder="Username"
                type="text"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700">Password</label>
              <input
                onChange={(event) => {
                  const password = event.target.value;
                  setLoginState({ ...loginState, password });
                }}
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="text-center mt-4">
              <button className=" bg-teal-600 py-3 px-8 text-white rounded-full mr-4" href="#your-link">
                Login
              </button>
            </div>
          </form>
          <div className="text-center mt-4">
            <Link to="/register" className="text-blue-500">Register</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    user: state
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (userState, history) => {
      dispatch(LoginAuthAction(userState, history))
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)