import { Card } from "react-bootstrap";
import { CardBody, CardHeader, CardText, CardTitle } from "reactstrap";


const About = () => {
    return (
        <div className="header py-28 justify-cnter md:pt-36 lg:text-left xl:pt-44 xl:pb-32 overflow-x-auto">
        <div className="container mx-auto py-6">
          <div className="bg-white rounded-lg shadow-lg p-6">
            <h1 className="text-2xl font-semibold text-gray-800 mb-4">Orthopedics and Pain Health</h1>
            <p className="text-gray-600 mb-4">
              Orthopedics is the branch of medicine that focuses on the diagnosis, treatment, and prevention of
              musculoskeletal disorders. This includes conditions related to bones, muscles, ligaments, tendons, and
              joints. Orthopedic doctors, also known as orthopedic surgeons, specialize in addressing a wide range of
              musculoskeletal problems.
            </p>
            <p className="text-gray-600 mb-4">
              Pain health, on the other hand, deals with the management of pain associated with various medical
              conditions. Chronic pain can be caused by musculoskeletal issues, injuries, arthritis, and other
              underlying health problems. Pain management specialists work to relieve pain and improve the quality of
              life for patients.
            </p>
            <p className="text-gray-600">
              The collaboration between orthopedic specialists and pain management experts is crucial in addressing
              conditions like back pain, joint pain, sports injuries, and more. By working together, they can provide
              comprehensive care to patients, helping them recover and manage their pain effectively.
            </p>
          </div>
        </div>
        </div>
      );
}

export default About;
