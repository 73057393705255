import React, { useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { showError, showSuccess } from "../../../Helpers/message";
import { RegisterService } from "../../../Services/auth";
import { getCsrfToken } from "../../../Services/token";
import { COLOR } from "../../Atom/Color";

const RegisterPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    email: "",
    loading: false,
    success: false,
    error: false,
  });
  const { error, success } = formData;

  const handleChange = (evt) => {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
    });
  };

  async function handleSubmit(evt) {
    evt.preventDefault();
    formData.loading = true;
    setFormData({ ...formData });
    await RegisterService(formData)
      .then((res) => {
        setFormData({
          ...formData,
          loading: false,
          success: 'Akun berhasil terbuat, silahkan konfirmasi ke email yang telah didaftarkan'
        });
      })
      .catch((err) => {
        console.log(err);
        setFormData({
          ...formData,
          loading: false,
          error: (
            <div className="alert alert-danger">{err.response.data.message ?? err.response.data.error.message}</div> || <div></div>
          ),
          success: false,
        });
      });
  }

  return (
    
    <div className="header flex justify-center items-center md:pt-36 lg:text-left xl:pt-44 xl:pb-32">
      <div className="card shadow-md p-6 bg-white max-w-md w-full">
        <h1 className="text-center text-2xl font-bold mb-4">Register</h1>
        <form onSubmit={handleSubmit}>
          {error && showError(error)}
          {success && showSuccess(formData.success)}
          <div className="mb-4">
            <label className="block" htmlFor="name">
              Full Name
            </label>
            <input
              className="w-full p-2 border rounded"
              name="name"
              onChange={handleChange}
              value={formData.name ?? ""}
              placeholder="Your name"
              type="text"
            />
          </div>
          <div className="mb-4">
            <label className="block" htmlFor="username">
              Username
            </label>
            <input
              className="w-full p-2 border rounded"
              name="username"
              value={formData.username ?? ""}
              placeholder="example"
              onChange={handleChange}
              type="text"
            />
          </div>
          <div className="mb-4">
            <label className="block" htmlFor="email">
              Email
            </label>
            <input
              className="w-full p-2 border rounded"
              name="email"
              value={formData.email ?? ""}
              onChange={handleChange}
              placeholder="example@gmailcom"
              type="email"
            />
          </div>
          <div className="mb-4">
            <label className="block" htmlFor="password">
              Password
            </label>
            <input
              className="w-full p-2 border rounded"
              name="password"
              placeholder="Password"
              type="password"
              onChange={handleChange}
            />
          </div>
          <div className="text-center">
            {formData.loading === false ? (
              <button className="bg-blue-500 text-white p-2 rounded">Register</button>
            ) : (
              <button className="bg-blue-500 text-white p-2 rounded cursor-not-allowed" disabled>Loading...</button>
            )}
            <br />
            <Link to="/login">Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
