import { useQuery } from 'react-query'
import { getAllScheduleDoctor, getScheduleByServiceAndDoctorUuid } from '../../../Services/doctor';
import { getAllServices } from '../../../Services/category';
import { FaCalendar, FaCalendarAlt } from 'react-icons/fa';
import { FOURTH_COLOR, MAIN_COLOR, SECOND_COLOR, THIRD_COLOR } from '../../../Helpers/Colour';
import { CreateReservationAuth, CreateReservationGuest, GuestContinueReservation, verifyGuest } from '../../../Services/reservation';
import { showError, showSuccess } from '../../../Helpers/message';
import {useLocation} from 'react-router'
import {BsFillExclamationCircleFill} from 'react-icons/bs'
import React, { useEffect, useState } from 'react';
const CreateReservationContinueGuest = ({isLogedIn}) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token'); 
    const emailQuery = queryParams.get('email'); 
    
    useEffect(()=> {
        
        if(isLogedIn){
            window.location.href = '/'
        }
    },[])
    const [formData, setFormData] = useState(
        {
            email: '',
            description: '',
            name: '',
            doctor_uuid: '',
            time_reservation_start: '',
            time_reservation_end: '',
            service_uuid: '',
            consultation_type: '',
            schedule_uuid: ''
        }
      )
    
      const [response, setResponse] = useState({
        successMessage: '',
        errorMessage: '',
        loading: false,
        error: false,
        success:false
      })
      const [showModal, setShowModal] = useState(false);
      const [selectedServiceUUID, setSelectedServiceUuid] = useState(null);
      const [selectedDoctorUUID, setSelectedDoctorUuid] = useState(null);
      const [doctorSchedules, setDoctorSchedules] = useState([])
      const [selectedDate, setSelectedDate] = useState(new Date());
      const [selectedTime, setSelectedTime] = useState('');
      const [selectedSession, setSelectedSession] = useState(10);
      const [scheduleId, setScheduleId] = useState('');
      const [service_uuid, setServiceUuid] = useState('');
      const [userState, setUserState] = useState(null);
      const [successModal, setSuccessModal] = useState(false)
      const { data: checkData, isError: checkErr, isFetched:checkFetch, isSuccess:checkSuccess, isLoading:checkLoading, refetch: checkRefetch } = useQuery(
        "checkData",
        () => verifyGuest(token, emailQuery),
        {
          refetchOnWindowFocus: false,
          retry: 2,
          onError: () => {
            window.location.href = '/'
          }
    
        }
      )
      const { data: scheduleList, isError, isFetched, isSuccess, isLoading } = useQuery(
        "getScheduleListDoctor",
        () => getScheduleByServiceAndDoctorUuid(selectedDoctorUUID, selectedServiceUUID),
        {
          refetchOnWindowFocus: false,
          retry: 2,
          enabled: !!selectedServiceUUID
    
        }
      )
    
      const closeModal = () => {
        setShowModal(false)
        setSelectedServiceUuid('')
        setSelectedDoctorUuid('')
        setSelectedDate(new Date())
        setSelectedTime('')
      }
      const closeModalSuccess = () => {
        setShowModal(false)
        setSelectedServiceUuid('')
        setSelectedDoctorUuid('')
        setSelectedDate(new Date())
        setSelectedTime('')
        setSuccessModal(false)
        checkRefetch()
      }
      const { data: doctorSchedule, isError: doctorScheduleError, isFetched: doctorScheduleFetch, isSuccess: doctorScheduleSuccess, isLoading: doctorScheduleLoading  } = useQuery(
        ["getListSchedule", service_uuid],
        () => getAllScheduleDoctor(service_uuid),
        {
          refetchOnWindowFocus: false,
          retry: 2,
    
        }
      )
    
      const { data: serviceList, isError: serviceListErr, isFetched: serviceListFetch, isSuccess: serviceListSuccess, isLoading: serviceListLoading  } = useQuery(
        "getLayanan",
        () => getAllServices(),
        {
          refetchOnWindowFocus: false,
          retry: 2,
    
        }
      )


      const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
      const openModal = (serviceUuid, doctorUuid) => {
        setSelectedServiceUuid(serviceUuid);
        setSelectedDoctorUuid(doctorUuid);
        setShowModal(true);
      
      };
      
      const selectedDaySchedules = selectedServiceUUID ? scheduleList?.data.data?.filter(
        (schedule) => schedule.day === selectedDate.toLocaleString('en-US', { weekday: 'long' }).toUpperCase()
        ) : [];
        
        const timeSlots = [];
        selectedDaySchedules?.forEach((schedule) => {
          const [startTime, endTime] = schedule.time?.split(' - ');
          
          // Menghilangkan AM/PM dari waktu yang dipilih
          const formattedStartTime = startTime.replace(/ [APap][Mm]$/, '');
          const formattedEndTime = endTime.replace(/ [APap][Mm]$/, '');
          
          const currentTime = new Date(`2023-10-30 ${formattedStartTime}`);
          const endSlotTime = new Date(`2023-10-30 ${formattedEndTime}`);
          
          while (currentTime < endSlotTime) {
            const start = currentTime.toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' });
            
            const end = new Date(currentTime);
            end.setMinutes(end.getMinutes() + selectedSession);
            const endSlot = new Date(end);
            
            // Pastikan waktu slot tidak melebihi waktu akhir yang ditentukan
            if (endSlot > endSlotTime) {
              break;
            }
            
            const timeSlot = `${start} - ${endSlot.toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' })}`;
            timeSlots.push(timeSlot);
            currentTime.setMinutes(currentTime.getMinutes() + selectedSession);
          }
        });
        
        const handleDateChange = (event) => {
          setSelectedDate(new Date(event.target.value));
          setSelectedTime('');
        };
        
        const handleTimeChange = (event) => {
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = days[selectedDate.getDay()];
        const shceduleFiltered = scheduleList?.data?.data.filter(schedule => String(schedule.day).toLocaleUpperCase() === String(dayName).toLocaleUpperCase());
        setSelectedTime(event.target.value);
        let selectedTime = event?.target?.value
        let selectedSchedule = shceduleFiltered
    
        if (selectedTime) {
          const selectedTimeRange = selectedTime?.split(' - ');
          const selectedStartTime = String(selectedTimeRange[0]).replace('.', ':');
          const selectedEndTime = String(selectedTimeRange[1]).replace('.', ':');
    
          selectedSchedule.some(item => {
            const hoursRange = item.time?.split(' - ');
            const hoursStartTime = hoursRange[0];
            const hoursEndTime = hoursRange[1];
    
            let selectedStartTimeDate = new Date(selectedDate.toDateString() + ' ' + selectedStartTime);
            let selectedEndTimeDate = new Date(selectedDate.toDateString() + ' ' + selectedEndTime);
    
            let hoursStartTimeDate = new Date(selectedDate.toDateString() + ' ' + hoursStartTime);
            let hoursEndTimeDate = new Date(selectedDate.toDateString() + ' ' + hoursEndTime);
    
            let isTimeWithinRange = selectedStartTimeDate >= hoursStartTimeDate && selectedEndTimeDate <= hoursEndTimeDate;
    
            if (isTimeWithinRange) {
              setScheduleId(item.uuid)
            } else {
              console.log("🚀 ~ file: index.jsx:183 ~ handleTimeChange ~ item:", isTimeWithinRange)
            }
          });
        } else {
          console.log(`Selected time ID '${selectedTime}' not found in timeMinutes.`);
        }
      };
    
      const handleSubmit = async () => {
    
        try{
        if (selectedDate && selectedTime) {
          // Mendapatkan tanggal yang dipilih
          const selectedDateParts = selectedDate.toISOString().split('T')[0];
          console.log("🚀 ~ file: index.jsx:260 ~ handleSubmit ~ selectedDateParts:", selectedDateParts)
    
          // Mendapatkan waktu yang dipilih
          let selectedTimeParts = selectedTime.split(' - ');
          console.log("🚀 ~ file: index.jsx:265 ~ handleSubmit ~ selectedTimeParts:", selectedTimeParts[0])
    
          // Gabungkan tanggal dan waktu sesuai dengan format yang diinginkan
    
          const padZero = (num, length) => num.toString().padStart(length, '0');
    
          const startDateTime = new Date(selectedDateParts + ' ' + String(selectedTimeParts[0]).replace('.', ':'));
          const endDateTime = new Date(selectedDateParts + ' ' + String(selectedTimeParts[1]).replace('.', ':'));
          
          const formattedStartDateTime = `${startDateTime.getFullYear()}-${padZero(startDateTime.getMonth() + 1, 2)}-${padZero(startDateTime.getDate(), 2)}T${padZero(startDateTime.getHours(), 2)}:${padZero(startDateTime.getMinutes(), 2)}:${padZero(startDateTime.getSeconds(), 2)}.${padZero(startDateTime.getMilliseconds(), 3)}Z`;
          
          const formattedEndDateTime = `${endDateTime.getFullYear()}-${padZero(endDateTime.getMonth() + 1, 2)}-${padZero(endDateTime.getDate(), 2)}T${padZero(endDateTime.getHours(), 2)}:${padZero(endDateTime.getMinutes(), 2)}:${padZero(endDateTime.getSeconds(), 2)}.${padZero(endDateTime.getMilliseconds(), 3)}Z`;
          
          console.log(`"time_reservation_start": "${formattedStartDateTime}",`);
          console.log(`"time_reservation_end": "${formattedEndDateTime}",`);
    
          const requestData = {
            name: formData.name,
            email: emailQuery,
            description: formData.description,
            doctor_uuid: selectedDoctorUUID,
            time_reservation_start: formattedStartDateTime, // Diperoleh dari perhitungan waktu yang benar
            time_reservation_end: formattedEndDateTime, // Diperoleh dari perhitungan waktu yang benar
            service_uuid: selectedServiceUUID,
            consultation_type: formData.consultation_type,
            schedule_uuid: scheduleId,
          };
          setResponse({
            ...response,
            loading: true
          })
          await GuestContinueReservation(requestData, token)
          .then(res => {
            console.log(res, 'data')
            if(res.data.statusCode === 200){
              setResponse({
                ...response,
                success: true,
                successMessage: `Jadwal berhasil terbuat
                untuk melihat data anda, bisa kunjungi halaman
                lihat reservasi simpan kode berikut untuk menjadi identitas
                anda ${res?.data?.data?.no_reservation}
                `,
                loading: false
              })
              setSuccessModal(true)
              closeModal()
            }
          }).catch((err) => {
            console.log(err)
            setResponse({
              ...response,
              error: true,
              errorMessage: err?.response?.data?.error?.message ?? err.response.data.message,
              loading: false
            })
          })
    
        }
      }catch(err){
        alert('telah terjadi kesalahan saat membooking jadwal dokter')
      }
    
      };
    
    
      const handleServiceChange = (event) => {
        const selectedValue = event.target.value;
        setServiceUuid(selectedValue);
      };
      
    
      return (
        <div className="header py-28 text-center md:pt-36 lg:text-left xl:pt-44 xl:pb-32 overflow-x-auto">
         
         {
          checkSuccess && checkData ? (
            <>
               {response.success && showSuccess(response.successMessage)}
          
          <div className="flex container flex-row justify-between items-center mb-4">
          <select
            className="appearance-none bg-transparent py-3 border-b border-teal-600 w-72  text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
            onChange={handleServiceChange}
            value={service_uuid}
          >
            <option value="">Pilih Jenis Konsultasi</option>
            {serviceListSuccess &&
              serviceList?.data?.data?.map((service) => (
                <option key={service.uuid} value={service.uuid}>
                  {service.name}
                </option>
              ))}
          </select>
    
          </div>
    
         
    
          {doctorScheduleSuccess && doctorSchedule?.data?.data?.map((doctor) => (
            <div key={doctor?.uuid} style={{ backgroundColor: MAIN_COLOR }} className="container rounded shadow p-4 mb-4">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                  <img
                    src="https://w7.pngwing.com/pngs/28/890/png-transparent-doctor-icon-vista-white-coat.png"
                    alt={doctor?.doctor_name}
                    className="w-10 h-10 rounded-full mr-2"
                  />
                  <h2 className="text-lg font-semibold">{doctor.doctor_name}</h2>
                </div>
              </div>
              <div className="mb-4">
               
                {doctor?.services?.map((service) => (
                  <div key={service.uuid}>
                    <div className="flex items-center justify-between" style={{ backgroundColor: SECOND_COLOR, padding: '10px' }}>
                      <b>{service.name}</b>
                     
                          <button
                            className="bg-teal-600 text-white rounded px-2 py-1 flex items-center"
                            onClick={() => openModal(service.uuid, doctor.uuid)}
                          >
                            Buat Janji
                            <FaCalendarAlt className="ml-2" />
                          </button>
                       
                    </div>
                    {renderServiceSchedule(service, doctor, daysOfWeek)}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="modal-container">
                <div className="bg-white w-full p-6 rounded shadow-lg">
              {response.error && showError(response.errorMessage)}
                  <h2 className="text-xl font-semibold mb-4">Buat Janji</h2>
                  <form>
    
                  <div className="flex items-center border-b border-teal-600 py-2 mb-4">
                    <input
                        name='email'
                        placeholder="Email anda"
                        className="appearance-none bg-black bg-opacity-30 border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="email"
                        aria-label="Email"
                        disabled
                        value={emailQuery}
                        readOnly
                    />
                    </div>

                    <div className="flex items-center border-b border-teal-600 py-2 mb-4">
                    <input
                        name='name'
                        placeholder="Nama Lengkap"
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        aria-label="Full name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    </div>

    
                      <div className="flex items-center border-b border-teal-600 py-2 mb-4">
                      <select
                        name="consultation_type"
                        onChange={(evt) => setFormData({ ...formData, consultation_type: evt.target.value })}
                        value={formData.consultation_type ?? ''}
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      >
                        <option value="">Pilih Jenis Konsultasi</option>
                        <option value="KONSULTASI ONLINE">KONSULTASI ONLINE</option>
                        <option value="TATAP MUKA">TATAP MUKA</option>
                      </select>
                    </div>
    
    
                    <div className="flex items-center border-b border-teal-600 py-2 mb-4">
                      <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="date" onChange={handleDateChange} value={selectedDate.toISOString().split('T')[0]} />
                    </div>
    
                    <div className="flex items-center border-b border-teal-600 py-2 mb-4">
                      
                      <select className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" onChange={handleTimeChange} value={selectedTime}>
                        <option value="">Pilih waktu</option>
                        {timeSlots.map((slot, index) => (
                          <option key={index} value={slot}>
                            {slot}
                          </option>
                        ))}
                      </select>
                    </div>
                    <textarea
                      style={{height: '100px'}}
                      placeholder='Masukan keluhan anda'
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      onChange={(evt) => setFormData({ ...formData, description: evt.target.value })}
                      value={formData.description ?? ''}
                    />
    
                    <div className="flex justify-end">
                      <button
                        type="button"
                        className="bg-teal-600 text-white rounded px-4 py-2 mr-2"
                        onClick={() => handleSubmit()}
                      >
                        Simpan
                      </button>
                      <button className="bg-red-500 text-white rounded px-4 py-2 modal-close" onClick={() => closeModal(false)}>
                        Close
                      </button>
                    </div>
                  </form>
    
                </div>
              </div>
            </div>
          )}
           {successModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="modal-container">
            <div className="bg-white w-full p-6 rounded shadow-lg">
                {response.success && showSuccess(response.successMessage)}
            
                <button className="mt-4 bg-red-500 text-white rounded px-4 py-2 modal-close" onClick={() => closeModalSuccess(false)}>
                  Close
                </button>
            </div>
          </div>
        </div>
      )}
            </>
          ) :(
            <>
            <div className='md:pt-36 lg:text-left xl:pt-44 xl:pb-32'>
              <center>

              <BsFillExclamationCircleFill size={100} color='red'/>
              <br/>
              <h1>Data tidak ditemukan</h1>
              </center>
            </div>
            </>
          )
         }
       
          </div>
        )

    function getScheduleForDay(service, day) {
        return service.list_schedule.filter((item) => item.day === day);
    }
        function renderServiceSchedule(service, doctor, daysOfWeek) {
            return (
              <div className="table-responsive overflow-x-auto">
                <table className="min-w-full divide-y divide-gray mb-3">
                  <thead style={{ backgroundColor: FOURTH_COLOR }}>
                    <tr>
                      {daysOfWeek.map((day) => (
                        <th
                          key={day}
                          className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {day}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: SECOND_COLOR }}>
                      {daysOfWeek.map((day) => (
                        <td
                          key={day}
                          className={`px-6 py-4 whitespace-no-wrap ${
                            getScheduleForDay(service, day).length === 0 ? 'bg-gray-200' : ''
                          }`}
                        >
                          <ul>
                            {getScheduleForDay(service, day).map((schedule, index) => (
                              <li key={index}>{`${schedule.time} (${schedule.available.join(', ')})`}</li>
                            ))}
                          </ul>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          }
          
};

export default CreateReservationContinueGuest;
