import { useQuery } from "react-query";
import { getAllServices } from "../../Services/category";
import { Link } from "react-router-dom";

const ServiceList = () => {

  const {data,isSuccess, isError, isFetching, isLoading} = useQuery('getDetailPost', () => getAllServices(),{
    refetchOnWindowFocus:false,
    retry:2
  })

  console.log(data,'data')

  if(isError) {
    return 'failed to get data'
  }
  if(isFetching){
    return 'fetching the data ...'
  }

  if(isLoading){
    return 'Please wait data is loading ...'
  }



  return (
    <div className="header py-28 text-center md:pt-36 lg:text-left xl:pt-44 xl:pb-32">
     
       <div className="pt-4 text-center">
            <div className="container px-4 sm:px-8 xl:px-4">
                <p className="mb-4 text-gray-800 text-3xl leading-10 lg:max-w-5xl lg:mx-auto"> Jenis Layanan</p>
            </div> 
      </div>

        <div id="features" className="cards-1">
          <div className="container px-4 sm:px-8 xl:px-4">
        {data?.data?.data?.map((service) => (
          <Link to={`/cari-layanan?layanan=${service?.uuid}`}>

           <div className="card m-2">
           <div className="card-image">
             <img height={100} width={100} src={service.image} alt="alternative" />
           </div>
           <div className="card-body">
             <h5 className="card-title">{service.name}</h5>
             {/* <p className="mb-4">
               Kami selalu siap melayani Anda dalam situasi darurat 24 jam sehari, 7 hari seminggu.
             </p> */}
           </div>
         </div>
         </Link>
        ))}
      </div>
    </div>
    </div>
    
  );
}

export default ServiceList