
import './footer.css'

const MainFooter = () => {
  return (
    <div className="footer bg-gray-800 text-gray-300">
      <div className="container px-4 sm:px-8 py-4">
        <div className="flex flex-col items-center sm:flex-row sm:items-center sm:justify-between">
          <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0">
            <img style={{ height: "200px" }} src="https://res.cloudinary.com/werich1/image/upload/v1698080808/perscience/assets/LOGO_ARTIKULAR_BARU_tranparent-01_sfhykh.png" className="h-8 mr-3" alt="Flowbite Logo" />
          </a>
          <div className="text-center sm:text-left">
            <p className="mb-2">Jl. Juanda</p>
            <p className="mb-2">admin@artikular.com</p>
            <p>6289898989898</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainFooter