import React, { useState } from 'react';
import { getReportReservation } from '../../../Services/reservation';
import { useQuery } from 'react-query';

const ReservationCheck = () => {
  const [email, setEmail] = useState(null);
  const [reservationData, setReservationData] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const { data: reportData, isError, isFetched, isSuccess, isLoading,refetch } = useQuery(
    "getReport",
    () => getReportReservation(email),
    {
      refetchOnWindowFocus: false,
      retry: 2,
      onSuccess: (res) => {
        setReservationData({...res?.data?.data})
      }

    }
  )

  const handleCheckReservation = () => {
    refetch()
  };
  const convertDateToDesiredFormat = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  

  return (
    <div className="header py-28 justify-cnter md:pt-36 lg:text-left xl:pt-44 xl:pb-32 overflow-x-auto">
      <h1 className="text-center font-semibold mb-4">Cek Reservasi</h1>
      <div className="container mb-4">
        <label htmlFor="email" className="block text-gray-700">Masukan email atau kode reservasi anda:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={handleEmailChange}
          className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        />
        <button
        onClick={handleCheckReservation}
        className="bg-teal-500 text-white py-2 px-4 rounded mt-4 hover:bg-teal-700"
      >
        Cek Reservasi
      </button>
      </div>
    
      {reservationData && (
        <div className="container mt-8 grid grid-cols-2 gap-4 p-4 border border-gray-300 rounded">
          <div>
            <h2 className="text-lg font-semibold">Detail Reservasi</h2>
            <p className='m-2'>Nama: {reservationData.name}</p>
            <p className='m-2'>Email: {reservationData.email}</p>
            <p className='m-2'>Nomor Reservasi: {reservationData.no_reservation}</p>
            <p className='m-2'>Dokter: {reservationData.doctor_name}</p>
            <p className='m-2'>Layanan: {reservationData.service_name}</p>
          </div>
          <div>
            <p className='m-2'>Sesi Mulai: {convertDateToDesiredFormat(reservationData.time_reservation_start)}</p>
            <p className='m-2'>Sesi Selesai: {convertDateToDesiredFormat(reservationData.time_reservation_end)}</p>
            <p className='m-2'>Deskripsi: {reservationData.description}</p>
            <p className='m-2'>Status: {reservationData.status}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReservationCheck;
