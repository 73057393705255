/* eslint-disable react/jsx-no-undef */

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './Components/Funsgional/About';
import HomePage from './Components/Pages/HomePage';
import NavbarComponent from './Components/Header/NavbarComponent';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import LoginPage from './Components/Pages/Auth/LoginPage';
import RegisterPage from './Components/Pages/Auth/RegisterPage';
import Protected from './Components/Pages/ProtectedRoutes';
import MainFooter from './Components/Footer/MainFooter';
import Terms from './Components/Funsgional/TermsCond';
import ConfirmEmail from './Components/Pages/ConfirmationEmail';
import ScheduleTable from './Components/Pages/Schedule';
import AuthReservation from './Components/Pages/Reservation/AuthReservation';
import ServiceList from './Components/Pages/ServiceList';
import UserProfile from './Components/Pages/Auth/Profile';
import DoctorDetail from './Components/Pages/DetailDoctor';
import CreateReservationGuest from './Components/Pages/Schedule/CreateContinueReservation';
import { getUserLocalStorage } from './Helpers/auth';
import ReservationCheck from './Components/Pages/Reservation/ReservationCheck';
import ListReservation from './Components/Pages/Auth/Reservation';
import SearchPageSchedule from './Components/Pages/Schedule/SearchSchedule';


const queryClient = new QueryClient()
const App = () => {

  const [auth, setAuth] = useState(false)
  const [userState, setUserState] = useState(null);
  useEffect(() => {
    islogen();
  }, []);

  const islogen = () => {
    const storage = getUserLocalStorage();
    if (storage) {
      setAuth(true);
      try {
        setUserState(JSON.parse(storage));
      } catch (err) {

      }
    }
  };
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>


        <NavbarComponent />
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path='/register' element={<RegisterPage />} />
          <Route path="/jadwal-dokter" element={<ScheduleTable isLogedIn={userState} />} />
          <Route path="/layanan" element={<ServiceList />} />
          <Route path="/reservasi/guest" element={<CreateReservationGuest isLogedIn={userState} />} />
          <Route path="/about" element={<About/>}/>
          {/* <Route path="/terms" element={<Terms/>}/> */}
          <Route path="/dokter/detail/:slug" element={<DoctorDetail />} />
          <Route path="/reservasi/:uuid" element={<AuthReservation />} />
          <Route path='/email-confirmation' element={<ConfirmEmail />} />
          <Route path='/reservasi/check' element={<ReservationCheck />} />
          <Route path='/cari-layanan' element={<SearchPageSchedule />} />

          <Route path='/profile' element={
            <Protected>
              <UserProfile isLogedIn={userState} />
            </Protected>
          } />

          <Route path='/profile/reservasi' element={
            <Protected>
              <ListReservation isLogedIn={userState} />
            </Protected>
          } />




        </Routes>
        <MainFooter />
      </BrowserRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
