import axios from 'axios'
import { API_VERSION, BASE_URL } from '../Config/Api'
import { decoreateUser, getTokenLocalStorage } from '../Helpers/auth'
const path = '/profile'

export const getMyProfile = async() => {
    return await axios.get(BASE_URL + path + API_VERSION + '/my-profile', {
        headers: {
            'Authorization': 'Bearer '+getTokenLocalStorage()
        }
    })
}

export const updateProfile = async (payload) => {
    return await axios.put(BASE_URL + path + API_VERSION + '/update', {
        birth: payload.birth,
        phone_number: payload.phone_number,
        name: payload.name,
        address: payload.address,
        gender: payload.gender
    }, {
        headers: {
            'Authorization': 'Bearer ' + getTokenLocalStorage()
        }
    })
}

export const profileDetailDoctor = async (uuid) => {
    const response = await axios.get(BASE_URL + path + API_VERSION + '/doctors/'+uuid)
    return response?.data.data
}

export const updateProfileImage = async (payload) => {
    const formImage = new FormData()
    const user = decoreateUser()
    formImage.append('image', payload.image)
    formImage.append('token', payload.token)
    console.log(formImage, 'form')
    const upload = await axios.put(BASE_URL + path + API_VERSION + `/image-update/${user.uuid}`,formImage,{
        headers: {
            'x-csrf-token': formImage.token,
            'Content-Type': 'multipart/form-data',
            'Authorization' : 'Bearer ' + getTokenLocalStorage()
        }
    })

    return upload
}

export const getDoctors = async () => {
    const data = await axios.get(BASE_URL + path + API_VERSION + `/doctors` )
    return data
}