import {get as getCookie, set as setCookie, remove} from 'js-cookie'

export const getUserLocalStorage = () => {
    return getCookie('user')
}

export const getTokenLocalStorage = () => {
    return getCookie('token')
}

export const setUserLocalStorage = (user) => {
    localStorage.setItem('user', JSON.stringify(user))
}

export const setTokenLocalStorage = (token) => {
    setCookie('token', token)
}
export const removeCookieAuth = (token) => {
    remove('token')
    remove('user')
}

export const decoreateUser = () => {
    const user = getCookie('user')
    if(user){
        return JSON.parse(user)
    }else{
        return null
    }
}