import React, { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { TiArrowMaximise as Bars3Icon, TiThMenu, TiThMenuOutline } from 'react-icons/ti';
import { LogoutAuthAction } from '../../redux/actions/authAction';
import { connect } from 'react-redux';
import { getUserLocalStorage } from '../../Helpers/auth';
import Swal from 'sweetalert2'

const DropdownContent = ({ logout }) => {

  const handleLogout = () => {
    Swal.fire({
      title: "Logout",
      text: "Kamu yakin ingin keluar?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Keluar"
    }).then((result) => {
      if (result.isConfirmed) {
        logout('', '/login')
      }
    });
  }

  return (
    <div className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-md" >
      <a href="/profile" className="block px-4 py-2 text-gray-800 hover:bg-cyan-100">
        Profile
      </a>
      <a href="/profile/reservasi" className="block px-4 py-2 text-gray-800 hover:bg-cyan-100">
        Reservasi
      </a>
      <a href="#" onClick={handleLogout} className="block px-4 py-2 text-gray-800 hover:bg-cyan-100">
        Logout
      </a>
    </div >
  );

}


function Navbar(props) {
  const [auth, setAuth] = useState(false);
  const [userState, setUserState] = useState(null);
  const [scrolled, setScrolled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const { logout } = props;

  useEffect(() => {
    isLogged();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isLogged = () => {
    const storage = getUserLocalStorage();
    if (storage) {
      setAuth(true);
      setUserState(JSON.parse(storage));
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const navbarStyle = {
    backgroundColor: scrolled ? '#C6EAF9' : '#C6EAF9',
  };

  return (
    <div className="fixed top-0 left-0 w-full z-50" style={navbarStyle}>
      <Disclosure as="div">
        <div className="container mx-auto px-4 py-2 flex items-center justify-between">
          <a href="/" className="hidden lg:flex">
            <img
              src="https://res.cloudinary.com/werich1/image/upload/v1698080808/perscience/assets/LOGO_ARTIKULAR_BARU_tranparent-01_sfhykh.png"
              alt="alternative"
              className="h-20 w-25"
            />
          </a>

          <div className="hidden lg:flex space-x-4">
            <a className="nav-link text-cyan-600 hover:text-cyan-600 font-semibold" href="/">
              Home
            </a>
            <a className="nav-link text-cyan-600 hover:text-cyan-600 font-semibold" href="/layanan">
              Layanan Kami
            </a>
            <a className="nav-link text-cyan-600 hover:text-cyan-600 font-semibold" href="/jadwal-dokter">
              Jadwal Dokter
            </a>
            <a className="nav-link text-cyan-600 hover:text-cyan-600 font-semibold" href="#pricing">
              Tentang Kami
            </a>
            <a className="nav-link text-cyan-600 hover:text-cyan-600 font-semibold" href="/reservasi/check">
              Cek Reservasi
            </a>
            {auth ? (
              <div className="relative inline-block text-left">
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="menu-button"
                >
                  <span className="absolute -inset-1.5"></span>
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOj12MDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </button>
                {showDropdown && <DropdownContent logout={logout} />}
              </div>
            ) : (
              <a href="/login" className="nav-link text-cyan-600 hover:text-cyan-600 font-semibold">
                Login
              </a>
            )}
          </div>

          <div className="lg:hidden flex items-center space-x-4">
            <Disclosure.Button>
              <TiThMenu className="w-6 h-6" />
            </Disclosure.Button>
          </div>
          <a href="/" className="lg:hidden flex">
            <img
              src="https://res.cloudinary.com/werich1/image/upload/v1698080808/perscience/assets/LOGO_ARTIKULAR_BARU_tranparent-01_sfhykh.png"
              alt="alternative"
              className="h-20 w-25"
            />
          </a>
          {auth ? (
            <div className="inline-block lg:hidden relative text-right">
              <button
                onClick={toggleDropdown}
                type="button"
                className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                id="menu-button"
              >
                <span className="absolute -inset-1.5"></span>
                <span className="sr-only">Open user menu</span>
                <img
                  className="h-8 w-8 rounded-full"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOj12MDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </button>
              {showDropdown && <DropdownContent logout={logout} />}
            </div>
          ) : null}
        </div>

        <Disclosure.Panel>
          <div className="flex flex-col lg:hidden space-y-2 pb-10">
            <a
              className="nav-link container mx-auto px-4 py-2 hover:bg-white hover:rounded text-cyan-600 hover:text-cyan-600 font-semibold"
              href="/"
            >
              Home
            </a>
            <a
              className="nav-link container mx-auto px-4 py-2 hover:bg-white hover:rounded text-cyan-600 hover:text-cyan-600 font-semibold"
              href="/layanan"
            >
              Layanan Kami
            </a>
            <a
              className="nav-link container mx-auto px-4 py-2 hover:bg-white hover:rounded text-cyan-600 hover:text-cyan-600 font-semibold"
              href="/jadwal-dokter"
            >
              Jadwal Dokter
            </a>
            <a
              className="nav-link container mx-auto px-4 py-2 hover:bg-white hover:rounded text-cyan-600 hover:text-cyan-600 font-semibold"
              href="/about"
            >
              Tentang Kami
            </a>
            <a
              className="nav-link container mx-auto px-4 py-2 hover:bg-white hover:rounded text-cyan-600 hover:text-cyan-600 font-semibold"
              href="/about"
            >
              Cek Reservasi
            </a>
            {auth ? (
              <div className="relative hidden lg:inline-block text-right">
                <button
                  onClick={toggleDropdown}
                  type="button"
                  className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  id="menu-button"
                >
                  <span className="absolute -inset-1.5"></span>
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOj12MDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </button>
                {showDropdown && <DropdownContent logout={logout} />}
              </div>
            ) : (
              <a
                className="nav-link container mx-auto px-4 py-2 hover:bg-white hover:rounded text-cyan-600 hover:text-cyan-600 font-semibold"
                href="/login"
              >
                Login
              </a>
            )}
          </div>
        </Disclosure.Panel>
      </Disclosure>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    user: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (userState, history) => {
      dispatch(LogoutAuthAction(userState, history));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);