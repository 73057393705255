export const formatReservationDateRange = (startTimeDate, endTimeDate) =>{
    const start = new Date(startTimeDate);
    const end = new Date(endTimeDate);
  
    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const months = [
      "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
  
    const startDay = days[start.getUTCDay()];
    const startDate = start.getUTCDate();
    const startMonth = months[start.getUTCMonth()];
    const startYear = start.getUTCFullYear();
    const startTime = start.getUTCHours() + ":" + String(start.getUTCMinutes()).padStart(2, '0');
    const endTime = end.getUTCHours() + ":" + String(end.getUTCMinutes()).padStart(2, '0');
  
    const formattedDate = `${startDay}, ${startDate} ${startMonth} ${startYear} Pukul ${startTime} - ${endTime}`;
    return formattedDate;
  }

  export const formatReservation = (startTimeDate, endTimeDate) => {
    const start = new Date(startTimeDate);
    const end = new Date(endTimeDate);
  
    const startYear = start.getUTCFullYear();
    const startMonth = String(start.getUTCMonth() + 1).padStart(2, '0');
    const startDate = String(start.getUTCDate()).padStart(2, '0');
    const startTime = String(start.getUTCHours()).padStart(2, '0') + ":" + String(start.getUTCMinutes()).padStart(2, '0');
  
    const endTime = String(end.getUTCHours()).padStart(2, '0') + ":" + String(end.getUTCMinutes()).padStart(2, '0');
  
    const formattedDate = `${startYear}-${startMonth}-${startDate} ${startTime} - ${endTime}`;
    return formattedDate;
  }

    
  export const formatDate = (startTimeDate) =>{
    const start = new Date(startTimeDate);

  
    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"];
    const months = [
      "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];
  
    const startDay = days[start.getUTCDay()];
    const startDate = start.getUTCDate();
    const startMonth = months[start.getUTCMonth()];
    const startYear = start.getUTCFullYear();
    const startTime = start.getUTCHours() + ":" + String(start.getUTCMinutes()).padStart(2, '0');
  
    const formattedDate = `${startDay}, ${startDate} ${startMonth} ${startYear} Pukul ${startTime} `;
    return formattedDate;
  }