import axios from 'axios'
import { API_VERSION, BASE_URL } from '../Config/Api'
import {getTokenLocalStorage} from '../Helpers/auth'
const path = '/user'
export const LoginService = async(state) => {
    return await axios.post(BASE_URL + '/user' + API_VERSION + '/login',{
        username: state.username,
        password: state.password
    })
}

export const CheckProfileService = async() => {
    return await axios.get(BASE_URL + '/profile' + API_VERSION + '/my-profile',{
        "headers": {
            "Authorization": 'Bearer ' + getTokenLocalStorage()
        }
    })
}

export const GuestProfileService = async() => {
    return await axios.get(BASE_URL + '/profile' + API_VERSION + '/guest-profile',{
        "headers": {
            "Authorization": 'Bearer ' + getTokenLocalStorage()
        }
    })
}

export const RegisterService = async (payload) => {
    delete payload.loading
    delete payload.error
    delete payload.success
    console.log(payload, 'pay')
    return await axios.post(BASE_URL + path + API_VERSION + '/register', payload)
}