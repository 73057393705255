import { useState } from "react";
import {useParams} from 'react-router-dom'
const AuthReservation = (props) => {

    const {uuid} = useParams()
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedTime, setSelectedTime] = useState('');
    const [selectedSession, setSelectedSession] = useState(10);

    const doctorSchedules = {
        schedule: [
          {
            id: 1,
            profile_uuid: 'd892e353-a09b-434d-b514-b1a179e2cf2d',
            day: 'MONDAY',
            time: '08:00 - 09:00',
            is_active: true,
          },
          {
            id: 2,
            profile_uuid: 'd892e353-a09b-434d-b514-b1a179e2cf2d',
            day: 'MONDAY',
            time: '13:00 - 15:00',
            is_active: true,
          },
          {
            id: 3,
            profile_uuid: 'd892e353-a09b-434d-b514-b1a179e2cf2d',
            day: 'MONDAY',
            time: '19:00 - 20:00',
            is_active: true,
          },
         
        ],
      };
      
    const selectedDaySchedules = doctorSchedules.schedule.filter(
        (schedule) => schedule.day === selectedDate.toLocaleString('en-US', { weekday: 'long' }).toUpperCase()
      );
    
      const timeSlots = [];
      selectedDaySchedules.forEach((schedule) => {
        const [startTime, endTime] = schedule.time?.split(' - ');
      
        // Menghilangkan AM/PM dari waktu yang dipilih
        const formattedStartTime = startTime.replace(/ [APap][Mm]$/, '');
        const formattedEndTime = endTime.replace(/ [APap][Mm]$/, '');
      
        const currentTime = new Date(`2023-10-30 ${formattedStartTime}`);
        const endSlotTime = new Date(`2023-10-30 ${formattedEndTime}`);
        
        while (currentTime < endSlotTime) {
          const start = currentTime.toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' });
          
          const end = new Date(currentTime);
          end.setMinutes(end.getMinutes() + selectedSession);
          const endSlot = new Date(end);
      
          // Pastikan waktu slot tidak melebihi waktu akhir yang ditentukan
          if (endSlot > endSlotTime) {
            break;
          }
      
          const timeSlot = `${start} - ${endSlot.toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' })}`;
          timeSlots.push(timeSlot);
          currentTime.setMinutes(currentTime.getMinutes() + selectedSession);
        }
      });
      
    
      const handleDateChange = (event) => {
        setSelectedDate(new Date(event.target.value));
        setSelectedTime('');
      };
    
      const handleTimeChange = (event) => {
        setSelectedTime(event.target.value);
      };
    
      const selectDateTime = () => {
        if (selectedDate && selectedTime) {
          // Mendapatkan tanggal yang dipilih
          const selectedDateParts = selectedDate.toISOString().split('T')[0];
    
          // Mendapatkan waktu yang dipilih
          const selectedTimeParts = selectedTime.split(' - ');
    
          console.log(selectedDateParts , 'part')
          // Gabungkan tanggal dan waktu sesuai dengan format yang diinginkan
          const startDateTime = `${selectedDateParts} ${selectedTimeParts[0]}:00`
          const endDateTime = `${selectedDateParts} ${selectedTimeParts[1]}:00`
    
          let startModify = startDateTime.replace(/\./g, ':');
          let endModify = endDateTime.replace(/\./g, ':');
          
          console.log(new Date(startModify));
          console.log(new Date(endModify));
        
        }
        
      };
    return (
        <div class="container py-28 text-center md:pt-36 lg:text-left xl:pt-44 xl:pb-32">
        <h2 class="text-2xl font-semibold mb-4">Form Konsultasi Online</h2>
        
            <div class="mb-4">
                <label htmlFor="name" class="block text-gray-600 font-medium">Nama Lengkap</label>
                <input id="name" type="text" name="name" class="w-full rounded border px-3 py-2" />
            </div>

            <div class="mb-4">
                <label htmlFor="description" class="block text-gray-600 font-medium">Keluhan anda</label>
                <textarea id="description" name="description" rows="3" class="w-full rounded border px-3 py-2"></textarea>
            </div>

            <div class="mb-4">
                <label htmlFor="time_reservation_start" class="block text-gray-600 font-medium">Pilih Tanggal</label>
                <input class="w-full rounded border px-3 py-2" type="date" onChange={handleDateChange} value={selectedDate.toISOString().split('T')[0]} />
              
            </div>

            <div class="mb-4">
                <label htmlFor="time_reservation_start" class="block text-gray-600 font-medium">Waktu</label>
                <select className="w-full rounded border px-3 py-2" onChange={handleTimeChange} value={selectedTime}>
                    <option value="">Pilih waktu</option>
                    {timeSlots.map((slot, index) => (
                    <option key={index} value={slot}>
                        {slot}
                    </option>
                    ))}
                </select>
            </div>

            <div class="mb-4">
                <label htmlFor="consultation_type" class="block text-gray-600 font-medium">Jenis Konsultasi</label>
                <select id="consultation_type" name="consultation_type" class="w-full rounded border px-3 py-2">
                    <option value="">Pilih Konsultasi</option>
                    <option value="KONSULTASI ONLINE">Konsultasi Online</option>
                    <option value="KONSULTASI ONLINE">TATAP MUKA</option>
                </select>
            </div>


            <div class="mt-6">
                <button onClick={selectDateTime} type="submit" class="bg-blue-500 text-white font-semibold rounded px-4 py-2 hover:bg-blue-600">Kirim</button>
            </div>
        {/* </form> */}
    </div>
    )
}

export default AuthReservation