
import {AuthActionType} from '../actions/authAction'
import {set as setCookie, remove as setRemove} from 'js-cookie'

let authState = {
    isLoggedIn: false,
    user:{
        uuid: "",
        name:""
    },
    token:""
}

const authReducer = (state = authState, action) => {
    
    switch (action.type) {
        case AuthActionType.LOGIN_SUCCESS:
            console.log(action.payload.uuid, 'user')
            let storage = {
                uuid: action.payload.uuid,
                name: action.payload.name,
            }
             authState = {
                isLoggedIn: true,
                user: {
                    uuid: action.payload.uuid,
                    name: action.payload.name
                }
            }
            setCookie('user', storage)
            setCookie('token', action.payload.token)
            return authState
        case AuthActionType.LOGIN_FAILED:
            return authState
        case AuthActionType.LOGOUT_SUCCESS:
            const logoutState={
                isLoggedIn:false,
                user:{}
            }
            setRemove('user')
            setRemove('token')
            return logoutState
        default:
            return state
    }
}

export default authReducer