import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CheckProfileService } from "../../Services/auth";
import { removeCookieAuth } from "../../Helpers/auth";

const Protected = ({ children }) => {
  const [isLogin, setLogin] = useState(false);
  const { push } = useLocation();

  useEffect(() => {
    checkProfile();
  }, []);

  const checkProfile = async () => {
    try {
      await CheckProfileService();
      setLogin(true);
    } catch (err) {
      localStorage.clear();
      removeCookieAuth()
      window.location.href = '/login'
    }
  };

  if (isLogin) {
    return children;
  } else {
    return null;
  }
};

export default Protected;
